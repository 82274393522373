<template>
  <div id="app">
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/Web.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

