<template>
  <div>
    <section class="header header-fixed-xl">
      <div class="header-top-line bg-primary">
        <div class="container">
          <div class="row">
            <nav
              class="
                navbar navbar-expand navbar-dark navbar-short
                justify-content-between
                w-100
              "
            >
              <ul class="navbar-nav">
                <li
                  class="nav-item dropdown dropdown-flat dropdown-flat-primary"
                >
                  <a
                    href="https://www.linkedin.com/company/hexa-care/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin fa-lg text-white mt-5"></i
                  ></a>
                </li>
                <li
                  class="nav-item dropdown dropdown-flat dropdown-flat-primary"
                >
                  <a
                    class="nav-link text-white"
                    href="https://www.jomanassecret.com/"
                    target="_blank"
                    ><span class="text-uppercase">Online Store</span></a
                  >
                </li>
              </ul>
              <span class="navbar-text text-uppercase d-flex align-items-center"
                ><span class="text-white-70 mr-1">Email:</span>
                <span class="text-white mr-1"> info@hexa.care</span>
                <i class="material-icons fs-1">mail</i></span
              >
            </nav>
          </div>
        </div>
      </div>
      <div class="header-main bg-white">
        <div class="container">
          <div class="row">
            <nav
              class="navbar navbar-expand-lg navbar-light w-100"
              id="header-navbar"
            >
              <a class="navbar-brand font-weight-bold" href="#">
                <img
                  src="../assets/images/logo.png"
                  alt="Hexa care logo"
                  width="250px"
                />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <a
                      class="nav-link text-uppercase"
                      data-toggle="smooth-scroll"
                      href="#about"
                      >About</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link text-uppercase"
                      data-toggle="smooth-scroll"
                      href="#vision"
                      >Vision</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link text-uppercase"
                      data-toggle="smooth-scroll"
                      href="#markets"
                      >Markets</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link text-uppercase"
                      data-toggle="smooth-scroll"
                      href="#resellers"
                      >Resellers</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link text-uppercase"
                      data-toggle="smooth-scroll"
                      href="#contacts"
                      >Contact Us</a
                    >
                  </li>
                </ul>

                <div class="navbar-spacer"></div>
                <a
                  class="btn btn-outline-primary text-nowrap"
                  href="/profile"
                  data-toggle="smooth-scroll"
                  target="_blank"
                  >Company Profile</a
                >
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="header-spacing-helper" style="height: 140px"></div>
    <section
      class="hero hero-fullscreen jarallax hero-bg-ready"
      data-speed="0.1"
      style="min-height: 800px"
      data-jarallax-original-styles="min-height: calc(100vh - 140px)"
    >
      <div class="hero-background">
        <img
          src="../assets/images/kuwait.jpg"
          alt="hero background"
          class="jarallax-img"
        />
      </div>
      <div
        class="
          hero-foreground hero-foreground-gradient-black-left
          d-flex
          flex-column
          justify-content-center
        "
      >
        <div class="container text-white py-14 py-md-23 py-lg-25">
          <div
            class="
              d-flex
              flex-column flex-lg-row
              text-center text-lg-left
              align-items-center align-items-lg-stretch
              justify-content-between
            "
          >
            <div
              class="mb-14 mb-lg-0 d-flex flex-column justify-content-between"
            >
              <div>
                <small
                  class="
                    fs-2
                    font-family-secondary
                    text-uppercase
                    font-weight-bold
                    letter-spacing-caption
                  "
                >
                  providing health care products since 2002
                </small>
                <h3
                  class="fs-10 fs-lg-8 font-italic font-weight-light"
                  style="margin: 50px"
                >
                  We are Hexa care. And together, we are shaping the future of
                  healthcare!
                </h3>
              </div>
              <div class="d-none d-lg-block">
                <a
                  href="#about"
                  class="btn btn-outline-light btn-square"
                  data-toggle="smooth-scroll"
                  data-target="#hero-end-anchor"
                  ><i class="material-icons">arrow_downward</i></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="hero-end-anchor"></div>
    <!-- main hero end -->

    <!-- "about" section start -->
    <section class="section" id="about">
      <div class="section-content container">
        <div class="row text-center text-lg-left">
          <div class="col-12 col-lg-6 mb-12 mb-lg-0 d-flex align-items-center">
            <div class="pr-0 pr-lg-10">
              <h1 class="mb-0">About Us</h1>
              <div class="fs-5 text-muted">What do we do?</div>
              <p class="mt-8 mb-11">
                Hexa Care has been one of Kuwait's most authoritative voices in
                the cosmetics and personal care industry for nearly two decades.
                We have spent years selling, distributing, and marketing a wide
                range of outstanding products and brands in both local and
                global markets. We also work with local companies to promote and
                sell their products. Moreover, we have a wide range of stores,
                and online shops supplied with the best equipment, making it one
                of the most prominent competitors in the Kuwaiti market.
              </p>
              <a href="/profile" class="btn btn-lg btn-primary"
                >Learn More - Company Profile</a
              >
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <img
              src="../assets/images/image-2.jpg"
              class="img-fluid w-100"
              alt="welcome image"
            />
          </div>
        </div>
        <div style="margin-top: 50px">
          <small
            class="
              fs-1
              font-family-secondary
              text-uppercase
              font-weight-bold
              letter-spacing-caption
              text-muted
            "
            >Acquired brands in Kuwait</small
          >
          <div class="row text-center mt-4">
            <div class="col-sm" style="margin: auto;display: block;">
              <a href="https://cnc-cosmetic.de/en/" target="_blank">
                <img src="../assets/images/cnc.png" width="150px" />
              </a>
            </div>
            <div class="col-sm" style="margin: auto;display: block;">
              <a href="https://www.apeiron.care/" target="_blank">
                <img src="../assets/images/aperion.png" width="200px" />
              </a>
            </div>
            <div class="col-sm" style="margin: auto;display: block;">
              <a href="https://www.keralock.de/en" target="_blank">
                <img src="../assets/images/keralock.png" width="190px" />
              </a>
            </div>
              <div class="col-sm" style="margin: auto;display: block;">
              <a href="https://pharmahermetic.com/" target="_blank">
                <img src="../assets/images/pharma_hermetic.png" width="180px" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- "about" section end -->
    <section
      class="section jarallax text-white"
      data-jarallax
      data-speed="0.2"
      id="vision"
    >
      <img
        class="section-background-image jarallax-img"
        src="../assets/images/values.jpg"
        alt="background image"
      />
      <div
        class="section-background-color"
        style="background-color: rgba(0, 0, 0, 0.5)"
      ></div>
      <div class="section-content container text-center">
        <h1>Vision</h1>
      </div>
      <div class="section-content container mt-10 mt-md-19 pt-2">
        <div class="card-slider card-slider-testimonials">
          <article class="card-slider-item col-12">
            <div class="card card-ghost text-center">
              <div class="card-body p-19 p-md-20">
                <p class="mb-11 font-weight-bold">
                  Our Vision is to be sustainable in the business by keeping up
                  the ethical value and to provide the best atmosphere for
                  everyone within and outside the company by upholding the proud
                  heritage of success, excellence, and commitment to our
                  customers’ interests. <br />
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
    <!-- "services" section start -->
    <section class="section bg-secondary" id="markets">
      <div class="section-content container">
        <div
          class="
            d-flex
            justify-content-center justify-content-sm-between
            align-items-center
          "
        >
          <div class="text-center text-sm-left">
            <small
              class="
                fs-1
                font-family-secondary
                text-uppercase
                font-weight-bold
                letter-spacing-caption
                text-muted
              "
              >The markets we deal with</small
            >
            <h1>Markets</h1>
          </div>
        </div>
        <div class="row justify-content-center mt-6">
          <div class="col-lg card m-2" style="width: 25rem">
            <div class="card-body m-6">
              <img
                src="../assets/images/healthcare.jpg"
                class="img-fluid"
                alt="Health Care"
                height="160"
                width="270"
              />

              <h1 class="mt-10 fs-6 fs-lg-7">Health Care</h1>
              <p class="card-text">
                Access to comprehensive, quality health care products are
                important for promoting and maintaining health, preventing, and
                managing disease, reducing unnecessary disability and premature
                death, and achieving health equity.
              </p>
            </div>
          </div>
          <div class="col-lg card m-2" style="width: 25rem">
            <div class="card-body m-6">
              <img
                src="../assets/images/haircare.jpg"
                class="img-fluid"
                alt="Hair Care"
                height="160"
                width="270"
              />

              <h1 class="mt-10 fs-6 fs-lg-7">Hair Care</h1>
              <p class="card-text">
                Beautiful and shiny hair always leaves good impression, which is
                why proper hair care is important. Hair care products are the
                key to have healthy hair and avoid extensive damage that can
                lead to hair loss.
              </p>
            </div>
          </div>
          <div class="col-lg card m-2" style="width: 25rem">
            <div class="card-body m-6">
              <img
                src="../assets/images/skincare.jpg"
                class="img-fluid"
                alt="Skin Care"
                height="160"
                width="270"
              />

              <h1 class="mt-10 fs-6 fs-lg-7">Skin Care</h1>
              <p class="card-text">
                Good skin care products are essential; It helps your skin stay
                in good condition: You're shedding skin cells throughout the
                day, so it is important to keep your skin glowing and in a
                healthy condition!
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-6">
          <div class="col-lg card m-2" style="width: 25rem">
            <div class="card-body m-6">
              <img
                src="../assets/images/makeup.jpg"
                class="img-fluid"
                alt="make up"
                height="160"
                width="270"
              />

              <h1 class="mt-10 fs-6 fs-lg-7">Make Up</h1>
              <p class="card-text">
                “The most beautiful makeup of a person is passion. But cosmetics
                are easier to buy” Nowadays, Makeup products have become an
                important part of our lives, and it is the empowering effect of
                these products that has drawn people from all over the world.
              </p>
            </div>
          </div>
          <div class="col-lg card m-2" style="width: 25rem">
            <div class="card-body m-6">
              <img
                src="../assets/images/dermo.jpg"
                class="img-fluid"
                alt="dermo"
                height="160"
                width="270"
              />

              <h1 class="mt-10 fs-6 fs-lg-7">Dermo cosmetics</h1>
              <p class="card-text">
                Dermo-Cosmetics traders providing you high - quality products
                from the best brands available. Dermo cosmetics is where
                cosmetics and dermatology – or beauty and health – meet.
              </p>
            </div>
          </div>
          <div class="col-lg card m-2" style="width: 25rem">
            <div class="card-body m-6">
              <img
                src="../assets/images/medical.jpg"
                class="img-fluid"
                alt="medical equipment"
                height="160"
                width="270"
              />

              <h1 class="mt-10 fs-6 fs-lg-7">Medical Equipment</h1>
              <p class="card-text">
                Medical equipment and tools are essential for saving a person's
                life or carrying out any procedure!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- "services" section end -->

    <!-- "call to action" section start -->
    <section class="section jarallax text-white" data-jarallax data-speed="0.2">
      <img
        class="section-background-image jarallax-img"
        src="../assets/images/needs.jpg"
        alt="background image"
      />
      <div class="section-content container">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            justify-content-between
            align-items-center
          "
        >
          <div
            class="
              d-flex
              flex-column
              align-items-center align-items-lg-start
              mb-14 mb-lg-0
              mr-0 mr-lg-14
            "
          >
            <span
              class="
                badge badge-pill badge-dark
                fs-1
                font-family-secondary
                text-uppercase
                font-weight-bold
                letter-spacing-caption
              "
            >
              Our Online Store
            </span>
            <h1 class="mt-5 mb-0 fs-6 fs-md-6 text-dark">
              “We are committed to providing people with high-quality,<br />
              healthy, and safe alternatives for their everyday needs.”
            </h1>
          </div>
          <a
            href="https://www.jomanassecret.com/"
            target="_blank"
            class="btn btn-lg btn-primary"
          >
            <span class="fs-2 lh-5 d-flex flex-nowrap align-items-center">
              <i
                class="
                  material-icons material-icons-size-auto
                  text-white-50
                  mr-1
                "
                >store</i
              >
              <span class="text-white-50 mr-2">Store</span>
              Jomana's Secret
            </span>
          </a>
        </div>
      </div>
    </section>
    <!-- "call to action" section end -->

    <!-- "team" section start -->
    <!-- <section class="section" id="resellers">
      <div class="section-content container">
        <small
          class="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted"
        >
          Where we sell your products?
        </small>
        <h1>Resellers</h1>

        <div class="row mt-17 tour-tabs">
          <div class="col-12 col-lg-4 pr-lg-0">
            <div class="tour-tabs-nav tour-tabs-nav-left nav">
              <a
                href="#tour-tab-1"
                class="tour-tabs-nav-link"
                data-toggle="pill"
              >
                <div></div>
                <div class="ml-9">
                  <h2 class="fs-5 mb-1">Pharmacies</h2>
                </div>
              </a>
              <a
                href="#tour-tab-2"
                class="tour-tabs-nav-link active"
                data-toggle="pill"
              >
                <div></div>
                <div class="ml-9">
                  <h2 class="fs-5 mb-1">Beauty Centers</h2>
                </div>
              </a>
              <a
                href="#tour-tab-3"
                class="tour-tabs-nav-link"
                data-toggle="pill"
              >
                <div></div>
                <div class="ml-9">
                  <h2 class="fs-5 mb-1">Online Stores</h2>
                </div>
              </a>
              <a
                href="#tour-tab-4"
                class="tour-tabs-nav-link"
                data-toggle="pill"
              >
                <div class="ml-9">
                  <h2 class="fs-5 mb-1">Salons & Other</h2>
                </div>
              </a>
            </div>
          </div>
          <div class="col-12 col-lg-8 pl-lg-0 mt-14 mt-lg-0 d-flex">
            <div class="tour-tabs-content tour-tabs-content-right tab-content">
              <div class="tab-pane fade" id="tour-tab-1">
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <img
                      src="../assets/images/pharmacies.jpg"
                      alt="Pharmacies"
                      class="w-100 h-100 object-fit-cover"
                    />
                  </div>
                  <div class="col-12 col-sm-7 mt-14 mt-sm-0">
                    <h2 class="fs-6 fs-lg-9">Pharmacies</h2>
                    <p class="mt-8">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" id="tour-tab-2">
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <img
                      src="../assets/images/beauty.jpg"
                      alt="Beauty Center"
                      class="w-100 h-100 object-fit-cover"
                    />
                  </div>
                  <div class="col-12 col-sm-7 mt-14 mt-sm-0">
                    <h2 class="fs-6 fs-lg-9">Beauty Centers</h2>
                    <p class="mt-8">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="tour-tab-3">
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <img
                      src="../assets/images/online.jpg"
                      alt="Online Stores"
                      class="w-100 h-100 object-fit-cover"
                    />
                  </div>
                  <div class="col-12 col-sm-7 mt-14 mt-sm-0">
                    <h2 class="fs-6 fs-lg-9">Online Stores</h2>
                    <p class="mt-8">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="tour-tab-4">
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <img
                      src="../assets/images/salon.jpg"
                      alt="salons"
                      class="w-100 h-100 object-fit-cover"
                    />
                  </div>
                  <div class="col-12 col-sm-7 mt-14 mt-sm-0">
                    <h2 class="fs-6 fs-lg-9">Salons & Other</h2>
                    <p class="mt-8">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- "team" section end -->

    <!-- "case results" section start -->
    <!-- <section class="section bg-secondary" id="cases">
      <div class="section-content container text-center">
        <small class="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
          Cases that we win
        </small>
        <h1>Case Results</h1>
      </div>

      <div class="section-content container mt-17">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="card border-0">
              <div class="card-body p-19 p-md-20">
                <div class="price font-weight-bold">
                  <div class="price-currency">$</div>
                  <div class="price-value">476</div>
                  <div class="price-description font-weight-normal">
                    million
                  </div>
                </div>
                <div class="ribbon ribbon-left ribbon-info ribbon-float-top-right mt-9 px-13">
                  New
                </div>
                <hr class="my-11" />
                <p>
                  Profi has secured a landmark MTBE settlement against some of
                  the country’s biggest oil companies, which have agreed to pay
                  $476 million in a suit involving the contamination of 153
                  public water systems nationally.
                </p>
                <div class="mt-11">
                  <a href="#" class="btn btn-link">Learn more</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mt-14 mt-lg-0">
            <div class="card border-0">
              <div class="card-body p-19 p-md-20">
                <div class="price font-weight-bold">
                  <div class="price-currency">$</div>
                  <div class="price-value">476</div>
                  <div class="price-description font-weight-normal">
                    million
                  </div>
                </div>
                <hr class="my-11" />
                <p>
                  Profi has secured a landmark MTBE settlement against some of
                  the country’s biggest oil companies, which have agreed to pay
                  $476 million in a suit involving the contamination of 153
                  public water systems nationally.
                </p>
                <div class="mt-11">
                  <a href="#" class="btn btn-link">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- "case results" section end -->

    <!-- "quote" section start -->
    <!-- <section class="section">
      <div class="section-content container">
        <div class="big-quote">
          <div class="big-quote-icon mb-12 mb-md-15 mb-lg-19">
            <i class="material-icons">format_quote</i>
          </div>
          <blockquote class="big-quote-contents">
            <p class="mb-0 fs-6 fs-md-9">
              If there were no bad people, there would be no good lawyers.
            </p>
            <footer class="big-quote-footer mt-11 mt-md-14 mt-lg-18">
              Charles Dickens
            </footer>
          </blockquote>
        </div>
      </div>
    </section> -->
    <!-- "quote" section end -->

    <!-- "testimonials" section start -->
    <!-- <section class="section jarallax text-white" data-jarallax data-speed="0.2" id="vision">
      <img class="section-background-image jarallax-img" src="../assets/images/values.jpg" alt="background image" />
      <div class="section-background-color" style="background-color: rgba(0, 0, 0, 0.5)"></div>
      <div class="section-content container text-center">
        <h1>Vision</h1>
      </div>
      <div class="section-content container mt-10 mt-md-19 pt-2">
        <div class="card-slider card-slider-testimonials">
          <article class="card-slider-item col-12">
            <div class="card card-ghost text-center">
              <div class="card-body p-19 p-md-20">
                <p class="mb-11">
                  Our Vision is to be sustainable in the business by keeping up the ethical value and
                  to provide the best atmosphere for everyone within and outside the company by
                  upholding the proud heritage of success, excellence, and commitment to our
                  customers’ interests. <br /><br />
                  To achieve our vision, we use the following strategy: to be a progressive,
                  reputable, and responsible healthcare company, offering high-quality products
                  and services that meet the needs of the local and wider communities we serve.<br /><br />
                  We wish to be the "preferred choice" both for customers, suppliers, and employees
                  by exceeding their expectations.<br /><br />
                  We look forward to inspire and be a model for companies who are willing to reach
                  their dreams.
                </p>

              </div>
            </div>
          </article>
        </div>
      </div>
    </section> -->
    <!-- "testimonials" section end -->

    <!-- "contacts" section start -->
    <section class="section" id="contacts">
      <div class="section-content container">
        <div class="row">
          <div class="col-12 col-lg-12 mb-14 mb-lg-0">
            <small
              class="
                fs-1
                font-family-secondary
                text-uppercase
                font-weight-bold
                letter-spacing-caption
                text-muted
              "
            >
              We answer within 24 hours
            </small>
            <h1 class="mb-4">Contact Us</h1>
            <!-- <form action="#" class="row mt-17">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="first_name" class="form-label">First name</label>
                  <input type="text" class="form-control" id="first_name" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="last_name" class="form-label">Last name</label>
                  <input type="text" class="form-control" id="last_name" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="phone" class="form-label">Phone</label>
                  <input type="text" class="form-control" id="phone" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="email" class="form-label">Email</label>
                  <input type="text" class="form-control" id="email" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="message" class="form-label">Message</label>
                  <textarea id="message" class="form-control" rows="3"></textarea>
                </div>

                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-lg">Send message</button>
                </div>
              </div>
            </form> -->
          </div>
          <div class="col-sm card border-0 bg-secondary mb-4 mr-1">
            <div class="card-body py-17 px-10 text-center">
              <div class="card-icon mb-6">
                <i class="material-icons">map</i>
              </div>
              <div
                class="
                  fs-1
                  lh-1
                  my-5
                  font-family-secondary
                  text-uppercase
                  font-weight-bold
                  letter-spacing-caption
                  text-muted
                "
              >
                Our address
              </div>
              <p class="mb-0 text-body">
                Salmiya, Kuwait. <br />
                Salam Al Mubarak Street <br />Reem Complex <br />
                6th Floor <br />
                Office # 21<br />
                <i class="fas fa-map-marked mr-2 text-primary"></i>
<a href="https://goo.gl/maps/xqMegD9N9jcthP1XA">Directions</a>
              </p>
            </div>
          </div>
          <div class="col-sm card border-0 bg-secondary mb-4 mr-1">
            <div class="card-body py-17 px-10 text-center">
              <div class="card-icon mb-6">
                <i class="material-icons">phone</i>
              </div>
              <div
                class="
                  fs-1
                  lh-1
                  my-5
                  font-family-secondary
                  text-uppercase
                  font-weight-bold
                  letter-spacing-caption
                  text-muted
                "
              >
                Mobile Phone
              </div>
              <p class="mb-0 text-body">(965) 65-777-407</p>
            </div>
          </div>
          <div class="col-sm card border-0 bg-secondary mb-4 mr-1">
            <div class="card-body py-17 px-10 text-center">
              <div class="card-icon mb-6">
                <i class="material-icons">email</i>
              </div>
              <div
                class="
                  fs-1
                  lh-1
                  my-5
                  font-family-secondary
                  text-uppercase
                  font-weight-bold
                  letter-spacing-caption
                  text-muted
                "
              >
                Email
              </div>
              <p class="mb-0 text-body">
                <a href="mailto:info@hexa.care?subject=Your Subject">
                  info@hexa.care
                </a>
              </p>
            </div>
            <!-- <div class="card border-0 bg-secondary ml-lg-9">
              <div class="card-body py-17 px-10 text-center">
                <div class="card-icon mb-6">
                  <i class="material-icons">access_time</i>
                </div>
                <div
                  class="fs-1 lh-1 my-5 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                  Working Hours
                </div>
                <p class="mb-0 text-body">09:00 AM - 05:00 PM</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <!-- "contacts" section end -->

    <!-- "map" section start -->
    <!-- <section class="gmap">
      <div class="gmap-embed" data-parallax-speed="0">
        <div class="gmap-embed-sizing-helper" style="padding-top: 650px"></div>
        <div class="gmap-embed-inner"
          data-options='{"center":{"lat":40.73774180898704,"lng":-73.96465326476259},"scrollwheel":false,"zoom":14,"disableDefaultUI":true,"draggable":true}'>
        </div>
      </div>

      <div class="gmap-infoboxes">
        <div class="gmap-infobox" data-latlng="40.744978 -73.951145">
          <div class="gmap-infobox-header">
            <h1 class="gmap-infobox-label">Long Island Office</h1>
          </div>

          <div class="gmap-infobox-details">
            <div class="gmap-infobox-details-item">
              47-02 11th St, Long Island City, NY 11101, USA
              <div>
                <a class="get-direction-link"
                  href="https://maps.google.com?daddr=47-02+11th+St%2C+Long+Island+City%2C+NY+11101%2C+USA"
                  target="_blank">Get directions</a>
              </div>
            </div>

            <div class="gmap-infobox-details-item">
              Mon-Fri: 8:00 am - 5:00 pm<br />
              Sat: 8:00 am - 3:00 pm<br />
              Sun: Closed
            </div>

            <div class="gmap-infobox-details-item">
              646-846-8996 <sup>Appointments</sup><br />
              212-355-2379 <sup>Fax</sup><br />
              <a href="mailto:contact@mail.com">contact@mail.com</a>
            </div>
          </div>
        </div>
        <div class="gmap-infobox" data-latlng="40.7323995 -73.9897919">
          <div class="gmap-infobox-header">
            <h1 class="gmap-infobox-label">Manhattan Office</h1>
          </div>

          <div class="gmap-infobox-details">
            <div class="gmap-infobox-details-item">
              100 E 12th St, New York, NY 10003
              <div>
                <a class="get-direction-link" href="https://maps.google.com?daddr=100+E+12th+St%2C+New+York%2C+NY+10003"
                  target="_blank">Get directions</a>
              </div>
            </div>

            <div class="gmap-infobox-details-item">
              Mon-Fri: 8:00 am - 5:00 pm<br />
              Sat: 8:00 am - 3:00 pm<br />
              Sun: Closed
            </div>

            <div class="gmap-infobox-details-item">
              646-846-8996 <sup>Appointments</sup><br />
              212-355-2379 <sup>Fax</sup><br />
              <a href="mailto:contact@mail.com">contact@mail.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    msg: String,
  },
};
</script>